@import "./components/variables";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, Roboto, "Helvetica Neue", "Noto Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $main;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: auto;
  margin: 0;
}

.wechat-tip {
  text-align: center;
  padding-top: 100px;
}

input {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  height: 36px;
  padding-left: 16px;
  margin: 0;
  &.flex {
    flex-grow: 1;
  }
  &[type='checkbox'] {
    width: 24px;
  }
}

button {
  border: none;
  height: 36px;
  min-width: 80px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: $blue;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.3);
  &:hover {
    background-color: darken($blue, 8%);
  }
}
