@import "../components/variables";

.invitation {
  width: 100vw;
  background-image: url($img + "/invitation.png");
  padding-top: 216%;
  background-size: 100%;
  background-repeat: no-repeat;

  .audio-wrap {
    width: 80px;
    height: 36px;
    position: fixed;
    right: -30px;
    top: 10vw;
    background-color: rgba(0,0,0,.5);
    border-radius: 18px 0 0 18px;
    transition: right 0.4s;
    .audio {
      position: absolute;
      left: 0;
      top: 0;
      @include size(36px);
      border-radius: 50%;
      background-color: rgba(0,0,0,.5);
      background-image: url($img + '/icon/note.png');
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center;
      animation: rotate linear 3s forwards infinite;
      animation-play-state: paused;
    }
    &.true {
      right: 0;
      .audio {
        animation-play-state: running;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}