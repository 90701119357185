@import "../components/variables";

.blue {
  color: $theme;
}

.vote-wrap {
  background-color: #94bdd7;
  min-height: 100vh;
  padding-bottom: 50px;
  .banner {
    background-image: url($img + '/vote/banner.png');
    background-repeat: no-repeat;
    height: 190px;
    background-size: cover;
  }
  .content-wrap {
    padding: 16px;
    .section {
      margin-bottom: 10px;
      background-color: rgba(255,255,255,1);
      border-radius: 8px;
    }
  }

  // 标题栏
  .title-wrap {
    padding-bottom: 16px;
    .title {
      text-align: center;
      padding-top: 16px;
      padding-bottom: 8px;
      font-size: 17px;
      font-weight: 800;
    }
    .desc {
      padding-left: 16px;
    }
  }

  // 搜索栏
  .search-wrap {
    display: flex;
    align-items: center;
    .input {
      flex-grow: 1;
      border: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: 14px;
      }
    }
    .btn {
      flex: none;
      background-color: $theme;
      color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      margin-right: 8px;
    }
  }

  // 菜单栏
  .tab-list {
    display: flex;
    .tab-item {
      flex-grow: 1;
      text-align: center;
      padding: 8px;
      font-size: 16px;
      &.true {
        position: relative;
        font-weight: 800;
        color: $theme;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 3px;
          width: 30px;
          height: 3px;
          border-radius: 4px;
          background-color: $theme;
        }
      }
    }
  }

  // 投票view
  .vote-list {
    display: flex;
    flex-wrap: wrap;
    .vote-item {
      &:nth-child(odd) {
        margin-right: 6%;
      }
      width: 47%;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      padding-bottom: 10px;
      position: relative;
      .num {
        position: absolute;
        font-weight: 800;
        color: #fff;
        background-color: rgba(0,0,0,.4);
        padding: 6px 0;
        width: 46px;
        text-align: center;
        border-radius: 0 0 20px 0;
      }
      .item {
        height: 30vw;
        background-color: #ddd;
        background-size: cover;
        background-position: center;
      }
      .name {
        text-align: center;
        padding: 8px 8px 8px 10px;
        font-weight: 800;
        @include text-eclipse;
      }
      .vote-panel {
        margin: 0 8px;
        background-color: #94bdd7;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-content: center;
        text-align: center;
        .left, .right {
          width: 50%;
          line-height: 30px;
        }
        .right {
          background-color: $theme;
          color: #fff;
          &.yes {
            background-color: lighten($theme, 20%);
          }
        }
      }
    }
  }

  // 排行榜
  .rank-wrap {
    padding: 8px 0;
    .title {
      padding: 8px 24px;
      font-weight: 600;
      position: relative;
      color: $theme;
      &:before {
        content: '';
        position: absolute;
        left: 16px;
        top: 12px;
        width: 2px;
        background-color: $theme;
        height: 13px;
      }
    }
    .rank-item {
      display: flex;
      align-items: center;
      padding: 8px 0;
      .number {
        flex: none;
        width: 40px;
        padding-left: 16px;
        font-weight: 600;
      }
      .avatar {
        flex: none;
        margin-right: 10px;
        @include size(40px);
        border-radius: 50%;
        background-size: cover;
      }
      .name {
        font-weight: 800;
        width: 180px;
        @include text-eclipse;
      }
      .vote {
        font-size: 16px;
        margin-left: auto;
        width: 80px;
        flex: none;
        text-align: right;
        padding-right: 16px;
        .blue {
          padding-right: 4px;
        }
      }
    }
  }
}