@import "../components/variables";

.vote-form {
  padding: 20px;
  font-size: 16px;
  .form-wrap {
    display: flex;
    margin-left: -20px;
    margin-bottom: 20px;
    .label {
      width: 136px;
      flex: none;
      text-align: right;
      padding-right: 16px;
      line-height: 36px;
    }
  }
  .submit-wrap {
    padding-left: 116px;
    padding-top: 16px;
  }
}