@import "../components/variables";

.vote-detail {
  background-color: #94bdd7;
  min-height: 100vh;
  padding: 8px 8px 200px 8px;

  .photo-wrap {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 16px;
    .img {
      width: 100%;
      background-color: #f8f8f8;
      min-height: 200px;
    }
    .content {
      padding: 0 16px;
      .name {
        font-weight: 800;
        padding: 6px 0;
        font-size: 16px;
      }
    }
  }

  .action-wrap {
    position: fixed;
    height: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.3);
    .badge {
      position: absolute;
      line-height: 24px;
      min-width: 70px;
      top: -24px;
      left: 16px;
      background-color: $theme;
      color: #fff;
      padding: 0 10px;
      border-radius: 8px 8px 0 0;
    }
    .left {
      font-size: 16px;
    }
    .right {
      margin-left: auto;
      line-height: 36px;
      background-color: $theme;
      color: #fff;
      border-radius: 6px;
      padding: 0 16px;
      font-weight: 800;
      &.yes {
        background-color: lighten($theme, 20%);
      }
    }
  }

  .back-btn {
    position: fixed;
    z-index: 9;
    bottom: 70px;
    right: 10px;
    @include size(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    font-size: 12px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.3);
  }
}