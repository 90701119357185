.blue {
  color: #3471b6;
}

.vote-wrap {
  background-color: #94bdd7;
  min-height: 100vh;
  padding-bottom: 50px;
}

.vote-wrap .banner {
  background-image: url("https://joey-index.oss-cn-chengdu.aliyuncs.com/assets/vote/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 190px;
}

.vote-wrap .content-wrap {
  padding: 16px;
}

.vote-wrap .content-wrap .section {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.vote-wrap .title-wrap {
  padding-bottom: 16px;
}

.vote-wrap .title-wrap .title {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 17px;
  font-weight: 800;
}

.vote-wrap .title-wrap .desc {
  padding-left: 16px;
}

.vote-wrap .search-wrap {
  align-items: center;
  display: flex;
}

.vote-wrap .search-wrap .input {
  border: none;
  flex-grow: 1;
}

.vote-wrap .search-wrap .input:focus {
  outline: none;
}

.vote-wrap .search-wrap .input::placeholder {
  font-size: 14px;
}

.vote-wrap .search-wrap .btn {
  color: #fff;
  background-color: #3471b6;
  border-radius: 4px;
  flex: none;
  margin-right: 8px;
  padding: 3px 10px;
}

.vote-wrap .tab-list {
  display: flex;
}

.vote-wrap .tab-list .tab-item {
  text-align: center;
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
}

.vote-wrap .tab-list .tab-item.true {
  color: #3471b6;
  font-weight: 800;
  position: relative;
}

.vote-wrap .tab-list .tab-item.true:after {
  content: "";
  background-color: #3471b6;
  border-radius: 4px;
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
}

.vote-wrap .vote-list {
  flex-wrap: wrap;
  display: flex;
}

.vote-wrap .vote-list .vote-item {
  background-color: #fff;
  border-radius: 8px;
  width: 47%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.vote-wrap .vote-list .vote-item:nth-child(odd) {
  margin-right: 6%;
}

.vote-wrap .vote-list .vote-item .num {
  color: #fff;
  text-align: center;
  background-color: #0006;
  border-radius: 0 0 20px;
  width: 46px;
  padding: 6px 0;
  font-weight: 800;
  position: absolute;
}

.vote-wrap .vote-list .vote-item .item {
  background-color: #ddd;
  background-position: center;
  background-size: cover;
  height: 30vw;
}

.vote-wrap .vote-list .vote-item .name {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 8px 8px 10px;
  font-weight: 800;
  overflow: hidden;
}

.vote-wrap .vote-list .vote-item .vote-panel {
  text-align: center;
  background-color: #94bdd7;
  border-radius: 4px;
  align-content: center;
  margin: 0 8px;
  display: flex;
  overflow: hidden;
}

.vote-wrap .vote-list .vote-item .vote-panel .left, .vote-wrap .vote-list .vote-item .vote-panel .right {
  width: 50%;
  line-height: 30px;
}

.vote-wrap .vote-list .vote-item .vote-panel .right {
  color: #fff;
  background-color: #3471b6;
}

.vote-wrap .vote-list .vote-item .vote-panel .right.yes {
  background-color: #78a5d8;
}

.vote-wrap .rank-wrap {
  padding: 8px 0;
}

.vote-wrap .rank-wrap .title {
  color: #3471b6;
  padding: 8px 24px;
  font-weight: 600;
  position: relative;
}

.vote-wrap .rank-wrap .title:before {
  content: "";
  background-color: #3471b6;
  width: 2px;
  height: 13px;
  position: absolute;
  top: 12px;
  left: 16px;
}

.vote-wrap .rank-wrap .rank-item {
  align-items: center;
  padding: 8px 0;
  display: flex;
}

.vote-wrap .rank-wrap .rank-item .number {
  flex: none;
  width: 40px;
  padding-left: 16px;
  font-weight: 600;
}

.vote-wrap .rank-wrap .rank-item .avatar {
  background-size: cover;
  border-radius: 50%;
  flex: none;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.vote-wrap .rank-wrap .rank-item .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-weight: 800;
  overflow: hidden;
}

.vote-wrap .rank-wrap .rank-item .vote {
  text-align: right;
  flex: none;
  width: 80px;
  margin-left: auto;
  padding-right: 16px;
  font-size: 16px;
}

.vote-wrap .rank-wrap .rank-item .vote .blue {
  padding-right: 4px;
}

.com-toast {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.com-toast .toast-msg {
  opacity: 0;
  background-color: #fff;
  border-radius: 8px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 42px;
  animation: 3s zoomInOut;
  display: flex;
  transform: scale3d(.3, .3, .3);
  box-shadow: 0 2px 6px #adadad80;
}

.com-toast .toast-msg .msg {
  white-space: nowrap;
}

.com-toast .toast-msg .msg-icon {
  background-image: url("https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_web/enterprise/icon-new.png");
  background-size: 80px 50px;
  margin-right: 7px;
}

.com-toast .toast-msg .msg-icon.success {
  background-position: 0 0;
  width: 16px;
  height: 16px;
}

.com-toast .toast-msg .msg-icon.warn {
  background-position: -20px 0;
  width: 16px;
  height: 16px;
}

.com-toast .toast-msg .msg-icon.error {
  background-position: -40px 0;
  width: 16px;
  height: 16px;
}

@keyframes zoomInOut {
  0%, 100% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  13%, 87% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.vote-form {
  padding: 20px;
  font-size: 16px;
}

.vote-form .form-wrap {
  margin-bottom: 20px;
  margin-left: -20px;
  display: flex;
}

.vote-form .form-wrap .label {
  text-align: right;
  flex: none;
  width: 136px;
  padding-right: 16px;
  line-height: 36px;
}

.vote-form .submit-wrap {
  padding-top: 16px;
  padding-left: 116px;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: auto;
  margin: 0;
  font-family: system-ui, -apple-system, Roboto, Helvetica Neue, Noto Sans, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.wechat-tip {
  text-align: center;
  padding-top: 100px;
}

input {
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 36px;
  margin: 0;
  padding-left: 16px;
  font-size: 16px;
}

input.flex {
  flex-grow: 1;
}

input[type="checkbox"] {
  width: 24px;
}

button {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  background-color: #0d6efd;
  border: none;
  border-radius: 4px;
  min-width: 80px;
  height: 36px;
  box-shadow: 1px 1px 4px #0000004d;
}

button:hover {
  background-color: #025bdf;
}

.vote-detail {
  background-color: #94bdd7;
  min-height: 100vh;
  padding: 8px 8px 200px;
}

.vote-detail .photo-wrap {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 16px;
  overflow: hidden;
}

.vote-detail .photo-wrap .img {
  background-color: #f8f8f8;
  width: 100%;
  min-height: 200px;
}

.vote-detail .photo-wrap .content {
  padding: 0 16px;
}

.vote-detail .photo-wrap .content .name {
  padding: 6px 0;
  font-size: 16px;
  font-weight: 800;
}

.vote-detail .action-wrap {
  z-index: 9;
  background-color: #fff;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 8px #0000004d;
}

.vote-detail .action-wrap .badge {
  color: #fff;
  background-color: #3471b6;
  border-radius: 8px 8px 0 0;
  min-width: 70px;
  padding: 0 10px;
  line-height: 24px;
  position: absolute;
  top: -24px;
  left: 16px;
}

.vote-detail .action-wrap .left {
  font-size: 16px;
}

.vote-detail .action-wrap .right {
  color: #fff;
  background-color: #3471b6;
  border-radius: 6px;
  margin-left: auto;
  padding: 0 16px;
  font-weight: 800;
  line-height: 36px;
}

.vote-detail .action-wrap .right.yes {
  background-color: #78a5d8;
}

.vote-detail .back-btn {
  z-index: 9;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 12px;
  display: flex;
  position: fixed;
  bottom: 70px;
  right: 10px;
  box-shadow: 0 0 8px #0000004d;
}

.invitation {
  background-image: url("https://joey-index.oss-cn-chengdu.aliyuncs.com/assets/invitation.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100vw;
  padding-top: 216%;
}

.invitation .audio-wrap {
  background-color: #00000080;
  border-radius: 18px 0 0 18px;
  width: 80px;
  height: 36px;
  transition: right .4s;
  position: fixed;
  top: 10vw;
  right: -30px;
}

.invitation .audio-wrap .audio {
  background-color: #00000080;
  background-image: url("https://joey-index.oss-cn-chengdu.aliyuncs.com/assets/icon/note.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: 3s linear infinite forwards paused rotate;
  position: absolute;
  top: 0;
  left: 0;
}

.invitation .audio-wrap.true {
  right: 0;
}

.invitation .audio-wrap.true .audio {
  animation-play-state: running;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.b96a20f1.css.map */
